<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <div class="fill-width d-flex justify-space-between flex-wrap">
                    <div class="mr-2"><v-btn :to="{name: 'admin.story-reports.index'}">Go back</v-btn></div>
                </div>
            </v-card-title>
            <v-card-text>
                <v-form v-if="report">
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.user.id" label="Reporter ID" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.user.name" label="Reporter" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea :value="report.accusation" label="Accusation" disabled></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.stream.user.id" label="Dependant ID" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.stream.user.name" label="Dependant" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="moment(report.created_at).format('LLL')" label="Reported at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn v-if="report.stream.user.streams_restricted_at" class="success" @click="showStreamRestrictionDialog = true">Cancel Suspension</v-btn>
                            <v-btn v-else class="error" @click="showStreamRestrictionDialog = true">Suspend</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="!report.stream.completed_at">
                        <v-col>
                            <v-btn @click="showStreamRefundingDialog = true">Make a Refund</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <Dialog @no="showStreamRestrictionDialog = false" @yes="handleRestrictStream" v-if="showStreamRestrictionDialog">
            {{ $t('dialogs.are_you_sure') }}
        </Dialog>

        <Dialog @no="showStreamRefundingDialog = false" @yes="handleRefund" v-if="showStreamRefundingDialog">
            {{ $t('dialogs.are_you_sure') }}
        </Dialog>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
    import Dialog from "@/components/app/setting/YesNoDialog";
    import {mapActions, mapMutations} from "vuex";
    import StreamReport from "@/models/admin/StreamReport";

    export default {
        name: "stream-reports-show",
        components: {
            AdminDashboardLayout,
            Dialog,
        },
        data: function () {
            return {
                report: false,
                player: {},
                errors: {},
                showStreamRestrictionDialog: false,
                showStreamRefundingDialog: false,
            }
        },
        methods: {
            ...mapMutations(['showSnackBar']),
            ...mapActions([
                'AdminRestrictStreams',
                'AdminRefundStreamTickets'
            ]),
            handleRestrictStream() {
                this.showStreamRestrictionDialog = false;

                this.AdminRestrictStreams(this.report.stream.user.id).then(() => {
                    this.fetchReport();
                })
            },
            handleRefund() {
                this.showStreamRefundingDialog = false;

                this.AdminRefundStreamTickets({
                    stream_id: this.report.stream.id,
                    viewer_id: this.report.user.id
                }).then(() => {
                    this.fetchReport();
                }).catch((error) => {
                    alert(error.response.data.message)
                })
            },
            async fetchReport() {
                this.report = await StreamReport.include([
                    'stream',
                    'stream.user',
                    'user',
                ]).find(this.$route.params.id)
            }
        },
        async mounted() {
            const reportId = this.$route.params.id
            this.fetchReport();
        },
    }
</script>

<style scoped>

</style>
